<template>
    <div class="column is-half">
        <b-table
            :selected="selectedProject"
            :loading="loading"
            :data="projects"
            default-sort="Name"
            striped
            narrowed
            hoverable
            paginated
            per-page="20"
            @update:selected="$emit('update:selectedProject', $event)">
            <b-table-column
                v-slot="props"
                sortable
                searchable
                field="Name"
                label="Name">
                <b>{{ props.row.Name }}</b>
            </b-table-column>
            <b-table-column
                v-slot="props"
                sortable
                searchable
                field="Description"
                label="Description">
                {{ props.row.Description }}
            </b-table-column>
            <b-table-column
                v-slot="props"
                sortable
                searchable
                field="ProjectMasterName"
                label="Project master">
                {{ props.row.ProjectMasterName }}
            </b-table-column>
            <template #empty>
                <div class="has-text-centered">
                    No projects
                </div>
            </template>
        </b-table>
    </div>
</template>
<script>
    export default {
        name: 'ProjectList',
        props: {
            loading: {
                type: Boolean,
                default: false
            },
            projects: {
                type: Array,
                default: () => []
            },
            selectedProject: {
                type: Object,
                default: () => null
            }
        },
        emits: ['update:selectedProject']
    };
</script>
<style scoped>
.headless-table thead {
    display: none;
}
</style>
