<template>
    <div>
        <h1 class="title is-1">
            UI Design System
        </h1>
        <p>
            This page documents the design elements used in the Common Library user interface, and should be used as a guide for front-end development.
            Refer to <code>UIDesignSystem.vue</code> for template markup.
        </p>
        <h2 class="title is-2 section-header">
            Links
        </h2>
        <div>
            <a href="/"><b>Link to some entity</b></a>
        </div>
        <div>
            <a href="/">Link with less importance</a>
        </div>

        <h2 class="title is-2 section-header">
            Buttons
        </h2>
        <div class="pad">
            <button class="button is-primary">
                Important action (is-primary)
            </button>
            <button class="button is-danger">
                Dangerous action (is-danger)
            </button>
            <button class="button is-info">
                Secondary action (is-info)
            </button>
            <button class="button">
                Other action
            </button>
        </div>
        <div class="pad">
            <button class="button is-primary">
                <b-icon
                    icon="play"
                    size="is-small" />
                <span>Run</span>
            </button>
            <button class="button is-danger">
                <b-icon
                    icon="skull"
                    size="is-small" />
                <span>Delete</span>
            </button>
            <button class="button is-info">
                <b-icon
                    icon="download"
                    size="is-small" />
                <span>Download</span>
            </button>
            <button class="button">
                <b-icon
                    icon="close-circle"
                    size="is-small" />
                <span>Cancel</span>
            </button>
        </div>

        <h2 class="title is-2 section-header">
            Iconography
        </h2>
        <h3 class="title is-3">
            Actions
        </h3>
        <table
            class="table is-narrow is-striped is-fullwidth">
            <thead>
                <tr>
                    <th>Icon</th>
                    <th>Name</th>
                    <th>Meaning</th>
                    <th>Comments</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        <b-icon
                            icon="close-circle" />
                    </td>
                    <td>close-circle</td>
                    <td>Cancel</td>
                    <td />
                </tr>
                <tr>
                    <td>
                        <b-icon
                            icon="delete" />
                    </td>
                    <td>delete</td>
                    <td>Delete</td>
                    <td>
                        Alternatively <b-icon
                            icon="delete-outline" /> delete-outline
                    </td>
                </tr>
                <tr>
                    <td>
                        <b-icon
                            icon="skull" />
                    </td>
                    <td>skull</td>
                    <td>Delete - unrecoverable / dangerous action</td>
                    <td />
                </tr>
                <tr>
                    <td>
                        <b-icon
                            icon="eraser" />
                    </td>
                    <td>eraser</td>
                    <td>Erase / clear something (not a persisting action)</td>
                    <td />
                </tr>
                <tr>
                    <td>
                        <b-icon
                            icon="pencil" />
                    </td>
                    <td>pencil</td>
                    <td>Edit</td>
                    <td>Standardize on single pencil icon</td>
                </tr>
                <tr>
                    <td>
                        <b-icon
                            icon="lead-pencil" />
                    </td>
                    <td>lead-pencil</td>
                    <td>Edit</td>
                    <td>fa-pencil-alt is used somewhere - should be replaced</td>
                </tr>
                <tr>
                    <td>
                        <b-icon
                            icon="magnify" />
                    </td>
                    <td>magnify</td>
                    <td>Any kind of search</td>
                    <td />
                </tr>
                <tr>
                    <td>
                        <b-icon
                            icon="plus-circle-outline" />
                    </td>
                    <td>plus-circle-outline</td>
                    <td>Add / create new</td>
                    <td>
                        Alternatively <b-icon
                            icon="plus" /> plus
                    </td>
                </tr>
                <tr>
                    <td>
                        <b-icon
                            icon="content-save" />
                    </td>
                    <td>content-save</td>
                    <td>Save</td>
                    <td />
                </tr>
                <tr>
                    <td>
                        <b-icon
                            icon="floppy" />
                    </td>
                    <td>floppy</td>
                    <td>Save</td>
                    <td>Standardize on single save icon!</td>
                </tr>
                <tr>
                    <td>
                        <b-icon
                            icon="rocket" />
                    </td>
                    <td>rocket</td>
                    <td>Execute some process</td>
                    <td />
                </tr>
                <tr>
                    <td>
                        <b-icon
                            icon="play" />
                    </td>
                    <td>play</td>
                    <td>Run (a query for example)</td>
                    <td>
                        <b-icon
                            icon="play-circle-outline" /> play-circle-outline has also been used, but should probably be replaced by simply play.
                    </td>
                </tr>
                <tr>
                    <td>
                        <b-icon
                            icon="auto-fix" />
                    </td>
                    <td>auto-fix</td>
                    <td>Action ("magic"), for example turn query into report.</td>
                    <td />
                </tr>
                <tr>
                    <td>
                        <b-icon
                            icon="close-octagon-outline" />
                    </td>
                    <td>close-octagon-outline</td>
                    <td>Terminate something (some action), for example remove query from published reports.</td>
                    <td />
                </tr>
                <tr>
                    <td>
                        <b-icon
                            icon="refresh" />
                    </td>
                    <td>refresh</td>
                    <td>Refresh / reload</td>
                    <td />
                </tr>
                <tr>
                    <td>
                        <b-icon
                            icon="replay" />
                    </td>
                    <td>replay</td>
                    <td>Re-open, re-insert, replay</td>
                    <td />
                </tr>
                <tr>
                    <td>
                        <b-icon
                            icon="download" />
                    </td>
                    <td>download</td>
                    <td>Download</td>
                    <td />
                </tr>
                <tr>
                    <td>
                        <b-icon
                            icon="upload" />
                    </td>
                    <td>upload</td>
                    <td>Upload some file</td>
                    <td />
                </tr>
            </tbody>
        </table>

        <h3 class="title is-3">
            Library Entities
        </h3>
        <table
            class="table is-narrow is-striped is-fullwidth">
            <thead>
                <tr>
                    <th>Icon</th>
                    <th>Name</th>
                    <th>Meaning</th>
                    <th>Comments</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        <b-icon
                            icon="book" />
                    </td>
                    <td>book</td>
                    <td>Library</td>
                    <td>Not much used</td>
                </tr>
                <tr>
                    <td>
                        <b-icon
                            icon="table" />
                    </td>
                    <td>table</td>
                    <td>Library set (CodeSet)</td>
                    <td />
                </tr>
                <tr>
                    <td>
                        <b-icon
                            icon="table-search" />
                    </td>
                    <td>table-search</td>
                    <td>A generic views query</td>
                    <td />
                </tr>
                <tr>
                    <td>
                        <b-icon
                            icon="rss" />
                    </td>
                    <td>rss</td>
                    <td>A generic views query that has been published as a report</td>
                    <td />
                </tr>
                <tr>
                    <td>
                        <b-icon
                            icon="graph" />
                    </td>
                    <td>graph</td>
                    <td>Explore library network / graph</td>
                    <td />
                </tr>
                <tr>
                    <td>
                        <b-icon
                            icon="format-list-numbered" />
                    </td>
                    <td>format-list-numbered</td>
                    <td>Log (release log)</td>
                    <td />
                </tr>
                <tr>
                    <td>
                        <b-icon
                            icon="script" />
                    </td>
                    <td>script</td>
                    <td>Library procedure (source code)</td>
                    <td />
                </tr>
            </tbody>
        </table>

        <h3 class="title is-3">
            Other
        </h3>
        <table
            class="table is-narrow is-striped is-fullwidth">
            <thead>
                <tr>
                    <th>Icon</th>
                    <th>Name</th>
                    <th>Meaning</th>
                    <th>Comments</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        <b-icon
                            icon="database" />
                    </td>
                    <td>database</td>
                    <td />
                    <td />
                </tr>
                <tr>
                    <td>
                        <b-icon
                            icon="folder-open" />
                    </td>
                    <td>folder-open</td>
                    <td>Navigate to some content</td>
                    <td />
                </tr>
                <tr>
                    <td>
                        <b-icon
                            icon="menu-down" />
                    </td>
                    <td>menu-down</td>
                    <td>Expand details</td>
                    <td />
                </tr>
                <tr>
                    <td>
                        <b-icon
                            icon="cog" />
                    </td>
                    <td>cog</td>
                    <td>Settings / options</td>
                    <td />
                </tr>
                <tr>
                    <td>
                        <b-icon
                            icon="filter" />
                    </td>
                    <td>filter</td>
                    <td>Specify or apply some filter condition</td>
                    <td />
                </tr>
                <tr>
                    <td>
                        <b-icon
                            icon="arrow-right" />
                    </td>
                    <td>arrow-right</td>
                    <td>Add/move/copy from left to right</td>
                    <td />
                </tr>
                <tr>
                    <td>
                        <b-icon
                            icon="arrow-left" />
                    </td>
                    <td>arrow-right</td>
                    <td>Add/move/copy from right to left</td>
                    <td />
                </tr>
                <tr>
                    <td>
                        <b-icon
                            icon="arrow-right-bold" />
                    </td>
                    <td>arrow-right-bold</td>
                    <td />
                    <td>Used in a table to illustrate mapping from A to B</td>
                </tr>
                <tr>
                    <td>
                        <b-icon
                            icon="file-excel" />
                    </td>
                    <td>file-excel</td>
                    <td>Excel file (typically export)</td>
                    <td />
                </tr>
                <tr>
                    <td>
                        <b-icon
                            icon="emoticon-sad" />
                    </td>
                    <td>emoticon-sad</td>
                    <td>Empty / nothing here</td>
                    <td />
                </tr>
                <tr>
                    <td>
                        <b-icon
                            icon="file" />
                    </td>
                    <td>file</td>
                    <td>File / some document content</td>
                    <td>Used for the help sections</td>
                </tr>
                <tr>
                    <td>
                        <b-icon
                            icon="script-text-outline" />
                    </td>
                    <td>script-text-outline</td>
                    <td>JSON / SQL</td>
                    <td />
                </tr>
                <tr>
                    <td>
                        <b-icon
                            icon="help" />
                    </td>
                    <td>help</td>
                    <td>Help</td>
                    <td />
                </tr>
                <tr>
                    <td>
                        <b-icon
                            icon="is-warning" />
                    </td>
                    <td>is-warning</td>
                    <td>Warning</td>
                    <td>This is used in TagFOrmatContainer, but does not seem to work</td>
                </tr>
                <tr>
                    <td>
                        <b-icon
                            icon="checkbox-marked-circle-outline" />
                    </td>
                    <td>checkbox-marked-circle-outline</td>
                    <td>Boolean TRUE</td>
                    <td />
                </tr>
                <tr>
                    <td>
                        <b-icon
                            icon="close-circle-outline" />
                    </td>
                    <td>close-circle-outline</td>
                    <td>Boolean FALSE</td>
                    <td />
                </tr>
                <tr>
                    <td>
                        <b-icon
                            icon="check" />
                    </td>
                    <td>check</td>
                    <td>Boolean TRUE</td>
                    <td />
                </tr>
                <tr>
                    <td>
                        <b-icon
                            icon="close" />
                    </td>
                    <td>close</td>
                    <td>Boolean FALSE</td>
                    <td />
                </tr>
                <tr>
                    <td>
                        <b-icon
                            icon="minus" />
                    </td>
                    <td>minus</td>
                    <td>No value</td>
                    <td />
                </tr>
                <tr>
                    <td>
                        <b-icon
                            icon="calendar-today" />
                    </td>
                    <td>calendar-today</td>
                    <td>Date</td>
                    <td />
                </tr>
                <tr>
                    <td>
                        <b-icon
                            icon="clock" />
                    </td>
                    <td>clock</td>
                    <td>Time</td>
                    <td />
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>

    export default {
    };
</script>

<style>
.section-header {
    margin-top: 20px;
    border-bottom: solid 1px silver;
}
.pad {
    margin-bottom: 10px;
}
</style>
