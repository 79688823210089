import { defineStore } from 'pinia';
import { useScopeStore } from '@/stores/scopeStore.js';
import { ref } from 'vue';

export const useElectricalComponentStore = defineStore('electricalComponent', () => {
    const electricalComponent = ref(null);

    const scopeStore = useScopeStore();

    function setElectricalComponent(value) {
        electricalComponent.value = value;
    }

    function getSelectedScope() {
        return scopeStore.getSelectedScope();
    }
    function updateSelectedScope(scope) {
        scopeStore.updateSelectedScope(scope);
    }

    return {
        electricalComponent,
        setElectricalComponent,
        getSelectedScope,
        updateSelectedScope
    };
});
