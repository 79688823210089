<template>
    <div>
        <div
            v-if="selectedScope"
            class="columns">
            <div
                class="column is-two-thirds">
                <b-table
                    v-model:selected="selectedPurchaseOrder"
                    :loading="purchaseOrdersLoading"
                    :data="purchaseOrders"
                    default-sort="Name"
                    striped
                    narrowed
                    hoverable
                    paginated
                    per-page="20">
                    <b-table-column
                        v-slot="props"
                        sortable
                        searchable
                        field="Name"
                        label="Name">
                        <b>{{ props.row.Name }}</b>
                    </b-table-column>
                    <b-table-column
                        v-slot="props"
                        sortable
                        searchable
                        field="Description"
                        label="Description">
                        {{ props.row.Description }}
                    </b-table-column>
                    <b-table-column
                        v-slot="props"
                        sortable
                        searchable
                        field="Contractor"
                        label="Contractor">
                        {{ props.row.Contractor }}
                    </b-table-column>
                    <b-table-column
                        v-slot="props"
                        sortable
                        searchable
                        field="ContractorDescription"
                        label="Company">
                        {{ props.row.ContractorDescription }}
                    </b-table-column>
                    <b-table-column
                        v-slot="props"
                        sortable
                        searchable
                        field="Project"
                        label="Project">
                        {{ props.row.Project }}
                    </b-table-column>
                    <b-table-column
                        v-slot="props"
                        sortable
                        searchable
                        field="ProjectDescription"
                        label="Project Description">
                        {{ props.row.ProjectDescription }}
                    </b-table-column>
                </b-table>
            </div>
            <div class="column">
                <div
                    class="block"
                    style="text-align: right">
                    <b-button
                        v-require-can-edit-code="accessArguments"
                        :model-value="isEditing"
                        type="is-primary"
                        @click="onNewPurchaseOrderClicked">
                        <b-icon
                            icon="plus"
                            size="is-small" />
                        <span>Create new purchase order</span>
                    </b-button>
                </div>
                <section
                    v-if="selectedPurchaseOrder && !editFormActive">
                    <div class="panel">
                        <div class="panel-heading is-flex is-justify-content-space-between">
                            <p>
                                Purchase Order attributes
                            </p>
                            <div>
                                <b-button
                                    v-require-can-edit-code="accessArguments"
                                    :disabled="!selectedPurchaseOrder"
                                    :model-value="isCreating"
                                    @click="editFormActive = true">
                                    <b-icon
                                        icon="pencil"
                                        size="is-small" />
                                    <span>Edit purchase order</span>
                                </b-button>
                            </div>
                        </div>
                        <b-table
                            class="headless-table"
                            :data="selectedPurchaseOrderAttributes"
                            narrowed
                            striped>
                            <b-table-column
                                v-slot="props"
                                field="property">
                                <b>{{ props.row.property }}</b>
                            </b-table-column>
                            <b-table-column
                                v-slot="props"
                                field="value">
                                <template v-if="props.row.property === 'IsValid'">
                                    <bool-element :value="props.row.value" />
                                </template>
                                <template v-else>
                                    {{ props.row.value }}
                                </template>
                            </b-table-column>
                            <template #empty>
                                <div class="has-text-centered">
                                    No attributes
                                </div>
                            </template>
                        </b-table>
                    </div>
                </section>
                <div
                    v-if="editFormActive"
                    class="">
                    <code-edit
                        :key="selectedPurchaseOrderId"
                        :show-cancel="true"
                        :code-id="selectedPurchaseOrderId"
                        :form-title="codeEditTitle"
                        :code-set-name="codeSetNamePurchaseOrder"
                        :reference-library-query-overrides="referenceLibraryQueryOverrides"
                        library="LCIPurchaseOrder"
                        :scopes="selectedScope"
                        form-title-class="is-4"
                        submit-button-label="Save"
                        @refresh="afterSave"
                        @cancel="editFormActive = false" />
                </div>
            </div>
        </div>
        <b-message v-else>
            Please select a scope.
        </b-message>
    </div>
</template>

<script>
    import BoolElement from '@/shared/components/BoolElement.vue';
    import CodeEdit from '@/shared/components/CodeEdit.vue';
    import { requireCanEditCode } from '@/shared/directives/requirePermission';
    import { genericViewQueryAsText, getCodeSets } from '@/shared/helpers/api';
    import { encodeIdBase64 } from '@/shared/helpers/utils';

    export default {
        directives: {
            'require-can-edit-code': requireCanEditCode
        },
        components: { BoolElement, CodeEdit },
        props: {
            selectedScope: {
                type: String,
                required: false,
                default: () => null
            },
            navigateToPurchaseOrder: {
                type: String,
                required: false,
                default: () => null
            }
        },
        emits: [
            'updatePurchaseOrder'
        ],
        data() {
            return {
                purchaseOrders: [],
                purchaseOrdersLoading: false,
                editFormActive: false,
                selectedPurchaseOrder: null,
                codeSetNamePurchaseOrder: ''
            };
        },
        computed: {
            accessArguments() {
                return {
                    libraryName: 'LCIPurchaseOrder',
                    scopes: [this.selectedScope]
                };
            },
            selectedPurchaseOrderId() {
                return this.selectedPurchaseOrder ? encodeIdBase64('Code', this.selectedPurchaseOrder.Id) : null;
            },
            codeEditTitle() {
                const verb = this.isEditing ? 'Edit' : 'New';
                return verb + ' Purchase Order';
            },
            isCreating() {
                return this.editFormActive && !this.selectedPurchaseOrder;
            },
            isEditing() {
                return this.editFormActive && !!this.selectedPurchaseOrder;
            },
            selectedPurchaseOrderAttributes() {
                return Object.keys(this.selectedPurchaseOrder)
                    .filter(key =>
                        key !== 'Id'
                        && !key.endsWith('_ID')
                        && this.selectedPurchaseOrder[key] !== null
                        && this.selectedPurchaseOrder[key] !== '')
                    .map(key => ({
                        property: key,
                        value: this.selectedPurchaseOrder[key]
                    }));
            },
            referenceLibraryQueryOverrides() {
                if (!this.selectedScope)
                    return null;

                const safeFacility = this.selectedScope.replaceAll('"', '\\"');
                return {
                    Project: `
                        FROM ProjectFacility
                        SELECT Project AS Identity
                        WHERE Facility = "` + safeFacility + `"
                        JOIN Project
                            SELECT Id, Name, Description, IsValid`
                };
            }
        },
        watch: {
            selectedScope: {
                async handler(newValue) {
                    if (newValue) {
                        await this.loadPurchaseOrders();
                    } else {
                        this.purchaseOrders = [];
                    }
                },
                immediate: true
            },
            selectedPurchaseOrder() {
                if (this.selectedPurchaseOrder) {
                    this.$emit('updatePurchaseOrder', this.selectedPurchaseOrder.Name);
                }
            },
            async purchaseOrdersLoading(newValue) {
                if (newValue) {
                    this.codeSetNamePurchaseOrder = await this.fetchCodeSetName('LCIPurchaseOrder', this.selectedScope);
                    this.editFormActive = false;
                    this.selectedPurchaseOrder = null;
                }
            }
        },
        async mounted() {
            this.codeSetNamePurchaseOrder = await this.fetchCodeSetName('LCIPurchaseOrder', this.selectedScope);
        },
        methods: {
            async loadPurchaseOrders() {
                this.purchaseOrdersLoading = true;
                const res = await genericViewQueryAsText(
                    this,
                    `FROM LCIPurchaseOrder
                        SELECT Id, Name, Description, Scope, Contractor, Contractor_ID, Project, Project_ID
                        WHERE IsValid = true AND Scope = @scope
                        JOIN Project
                          SELECT Description AS ProjectDescription
                        END
                        JOIN Contractor
                            SELECT Description AS ContractorDescription
                        END
                        ORDER BY Name`,
                    [{ name: '@scope', value: `"${this.selectedScope}"` }]
                );
                this.purchaseOrders = res.data;
                this.purchaseOrdersLoading = false;
                this.openPurchaseOrder(this.navigateToPurchaseOrder);
            },
            openPurchaseOrder(po) {
                if (po) {
                    this.selectedPurchaseOrder = this.purchaseOrders.find(x => x.Name === po);
                }
            },
            async fetchCodeSetName(libraryName, scope) {
                const res = await getCodeSets(this, libraryName, scope);
                return res.length === 1 ? res[0].name : null;
            },
            onNewPurchaseOrderClicked() {
                this.selectedPurchaseOrder = null;
                this.editFormActive = true;
            },
            async afterSave() {
                this.editFormActive = false;
                await this.loadPurchaseOrders();
            }
        }
    };
</script>

<style scoped>

</style>
