<template>
    <div v-if="result">
        <h3 class="title is-3">
            <b-icon
                v-if="result.matchFound"
                icon="check-circle"
                style="color: green;" />
            <b-icon
                v-else
                icon="close-circle"
                style="color: red;" />
            {{ result.number }}
        </h3>

        <template
            v-for="(warning, i) in result.warnings"
            :key="i + warning.message">
            <template v-if="warning.type === 'NON_STANDARD_CHARACTERS' && !result.matchFound">
                <b-message
                    type="is-warning"
                    has-icon>
                    The ENS number contains some non-standard characters that may have caused matching to fail:
                    <br>
                    {{ replaceInvalidChars(result.number) }}
                </b-message>
            </template>
            <template v-else>
                <!--Any unhandled warning types are displayed directly-->
                <b-message
                    type="is-warning"
                    has-icon>
                    {{ warning.type }}: {{ warning.message }}
                </b-message>
            </template>
        </template>

        <template v-if="result.matchFound">
            <div
                v-for="match in result.matches"
                :key="match.formatName"
                class="block">
                <h4 class="title is-4">
                    {{ match.formatDescription }}
                </h4>

                <h4 class="subtitle is-5">
                    Syntax: <b>{{ match.syntax }}</b>
                </h4>
                <table class="table">
                    <thead>
                        <tr>
                            <th>Element name</th>
                            <th>Reference</th>
                            <th>Value</th>
                            <th>Lookup description</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="element in match.elements">
                            <td>{{ element.description }}</td>
                            <td>{{ element.referenceType }}</td>
                            <td>{{ element.value }}</td>
                            <td>{{ element.valueDescription }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </template>

        <p v-else>
            No format for the selected category matched the given number.
        </p>
    </div>
</template>

<script>
    export default {
        name: 'EnsNumberValidationResult',
        props: {
            result: {
                type: Object,
                default: null
            }
        },
        methods: {
            replaceInvalidChars(string) {
                return string.replace(/[^ -~]+/g, '�');
            }
        }
    };
</script>

<style scoped>

</style>
