<template>
    <spinner :loading="loading">
        <div
            v-if="!loading"
            class="columns is-multiline is-desktop is-8 is-variable">
            <div class="column">
                <div class="columns">
                    <div class="column">
                        <nav class="level">
                            <div class="level-item has-text-centered">
                                <div>
                                    <p class="heading">
                                        Library
                                    </p>
                                    <p class="title">
                                        {{ library.name }}
                                    </p>
                                </div>
                            </div>
                            <div class="level-item has-text-centered">
                                <div>
                                    <p class="heading">
                                        Date modified
                                    </p>
                                    <p class="title is-5">
                                        {{ $filters.dateFormatShort(library.dateUpdated) }}
                                    </p>
                                </div>
                            </div>
                            <div class="level-item has-text-centered">
                                <div>
                                    <p class="heading">
                                        Date created
                                    </p>
                                    <p class="title is-5">
                                        {{ $filters.dateFormatShort(library.dateCreated) }}
                                    </p>
                                </div>
                            </div>
                        </nav>
                    </div>

                    <div class="column is-clearfix">
                        <div class="field is-grouped is-pulled-right">
                            <p class="control">
                                <button
                                    class="button"
                                    @click="cancel">
                                    <b-icon
                                        icon="close-circle"
                                        size="is-small" />
                                    <span>Cancel</span>
                                </button>
                            </p>
                            <p class="control">
                                <button
                                    class="button is-info"
                                    @click="newAD">
                                    <b-icon
                                        icon="plus-circle-outline"
                                        size="is-small" />
                                    <span>New attribute definition</span>
                                </button>
                            </p>
                            <p class="control">
                                <a
                                    class="button is-primary"
                                    :class="{'is-loading': saveLoading}"
                                    @click="initSave">
                                    <b-icon
                                        icon="content-save"
                                        size="is-small" />
                                    <span>Save</span>
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="column is-10 is-offset-1 has-margin-top2 box">
                <div class="columns">
                    <div class="column is-three-fifths">
                        <h2 class="title is-5">
                            Library Properties
                        </h2>
                        <div class="field is-horizontal">
                            <div class="field-label">
                                <label class="label">Description</label>
                            </div>
                            <div class="field-body">
                                <div class="field">
                                    <p class="control">
                                        <input
                                            v-model="libraryEdited.description"
                                            class="input"
                                            type="text">
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="field is-horizontal">
                            <div class="field-label">
                                <label class="label">Definition</label>
                            </div>
                            <div class="field-body">
                                <div class="field">
                                    <p class="control">
                                        <input
                                            v-model="libraryEdited.definition"
                                            class="input"
                                            type="text">
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="field is-horizontal">
                            <div class="field-label">
                                <label class="label">Alias</label>
                            </div>
                            <div class="field-body">
                                <div class="field">
                                    <p class="control">
                                        <input
                                            v-model="libraryEdited.alias"
                                            class="input"
                                            type="text">
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="field is-horizontal">
                            <div class="field-label">
                                <label class="label">Alias for Name</label>
                            </div>
                            <div class="field-body">
                                <div class="field">
                                    <p class="control">
                                        <input
                                            v-model="libraryEdited.nameAlias"
                                            class="input"
                                            type="text">
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div
                            v-show="!libraryEdited.isForeignObject"
                            class="field is-horizontal">
                            <div class="field-label">
                                <label class="label">Regex for Name</label>
                            </div>
                            <div class="field-body">
                                <div class="field">
                                    <p class="control">
                                        <input
                                            v-model="libraryEdited.codeNameRegex"
                                            class="input"
                                            type="text">
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div
                            v-show="!libraryEdited.isForeignObject"
                            class="field is-horizontal">
                            <div class="field-label">
                                <label class="label">Regex for Description</label>
                            </div>
                            <div class="field-body">
                                <div class="field">
                                    <p class="control">
                                        <input
                                            v-model="libraryEdited.descriptionRegex"
                                            class="input"
                                            type="text">
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="field is-horizontal">
                            <div class="field-label">
                                <label class="label">Specialised UI</label>
                            </div>
                            <div class="field-body">
                                <div class="field">
                                    <p class="control">
                                        <b-select
                                            v-model="libraryEdited.specializedUI"
                                            placeholder="Select specialised UI">
                                            <option :value="null">
                                                No specialised UI
                                            </option>
                                            <option
                                                v-for="option in specializedUIs"
                                                :key="option"
                                                :value="option">
                                                {{ option }}
                                            </option>
                                        </b-select>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="field is-horizontal">
                            <div class="field-label">
                                <label class="label">Library type</label>
                            </div>
                            <div class="field-body">
                                <div class="field">
                                    <p class="control">
                                        <b-select
                                            v-model="libraryEdited.isForeignObject">
                                            <option
                                                v-for="type in libraryTypeOptions"
                                                :key="type.value"
                                                :value="type.value">
                                                {{ type.name }}
                                            </option>
                                        </b-select>
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div
                            v-show="libraryEdited.isForeignObject"
                            class="field is-horizontal">
                            <div class="field-label">
                                <label class="label">Foreign app name</label>
                            </div>
                            <div class="field-body">
                                <div class="field">
                                    <p class="control">
                                        <input
                                            v-model="libraryEdited.foreignAppName"
                                            class="input"
                                            type="text">
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="field is-horizontal">
                            <div class="field-label">
                                &nbsp;
                            </div>
                            <div class="field-body">
                                <div class="field">
                                    <p class="control">
                                        <b-checkbox
                                            v-model="libraryEdited.isGlobal"
                                            type="is-info" />
                                        <strong>Is global</strong>
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div
                            v-show="!libraryEdited.isGlobal"
                            class="field is-horizontal">
                            <div class="field-label">
                                <label class="label">Scope type</label>
                            </div>
                            <div class="field-body">
                                <div class="field">
                                    <p class="control">
                                        <b-select
                                            v-model="libraryEdited.scopeType"
                                            placeholder="Select scope type">
                                            <option
                                                v-for="st in filteredScopeTypes"
                                                :key="st"
                                                :value="st">
                                                {{ st }}
                                            </option>
                                        </b-select>
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div
                            v-show="!libraryEdited.isGlobal"
                            class="field is-horizontal">
                            <div class="field-label">
                                &nbsp;
                            </div>
                            <div class="field-body">
                                <div class="field">
                                    <p class="control">
                                        <b-checkbox
                                            v-model="libraryEdited.isScopeSpecific"
                                            type="is-info" />
                                        <strong>Is scope specific</strong>
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div
                            v-show="!libraryEdited.isForeignObject"
                            class="field is-horizontal">
                            <div class="field-label">
                                &nbsp;
                            </div>
                            <div class="field-body">
                                <div class="field">
                                    <p class="control">
                                        <b-checkbox
                                            v-model="libraryEdited.isCaseSensitive"
                                            type="is-info" />
                                        <strong>Is case sensitive</strong>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div
                            v-show="!libraryEdited.isForeignObject"
                            class="field is-horizontal">
                            <div class="field-label">
                                &nbsp;
                            </div>
                            <div class="field-body">
                                <div class="field">
                                    <p class="control">
                                        <b-checkbox
                                            v-model="libraryEdited.isReadOnly"
                                            type="is-info" />
                                        <strong>Is read only</strong>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div
                            v-show="!libraryEdited.isForeignObject"
                            class="field is-horizontal">
                            <div class="field-label">
                                &nbsp;
                            </div>
                            <div class="field-body">
                                <div class="field">
                                    <p class="control">
                                        <b-checkbox
                                            v-model="libraryEdited.nameInIdentity"
                                            type="is-info" />
                                        <strong>Name in identity</strong>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div
                            v-show="!libraryEdited.isForeignObject"
                            class="field is-horizontal">
                            <div class="field-label">
                                &nbsp;
                            </div>
                            <div class="field-body">
                                <div class="field">
                                    <p class="control">
                                        <b-checkbox
                                            v-model="libraryEdited.nameMayChange"
                                            :disabled="libraryEdited.nameInIdentity"
                                            title="Can only be set if Name is not part of identity"
                                            type="is-info" />
                                        <strong>Name may be changed</strong>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div
                            v-show="!libraryEdited.isForeignObject"
                            class="field is-horizontal">
                            <div class="field-label">
                                &nbsp;
                            </div>
                            <div class="field-body">
                                <div class="field">
                                    <p class="control">
                                        <b-checkbox
                                            v-model="libraryEdited.areNamesUpperCase"
                                            type="is-info" />
                                        <strong>UpperCase Names</strong>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div
                            v-show="!libraryEdited.isForeignObject"
                            class="field is-horizontal">
                            <div class="field-label">
                                &nbsp;
                            </div>
                            <div class="field-body">
                                <div class="field">
                                    <p class="control">
                                        <b-checkbox
                                            v-model="libraryEdited.descriptionIsRequired"
                                            type="is-info" />
                                        <strong>Description required</strong>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <p
                            v-for="error in libraryErrors"
                            :key="error"
                            class="has-text-danger">
                            {{ error }}
                        </p>
                    </div>

                    <div class="column">
                        <div class="columns is-multiline">
                            <div class="column is-full">
                                <h2 class="title is-5">
                                    Library Groups
                                </h2>
                                <tag-list
                                    :icon-class="'fa fa-trash'"
                                    :tags="libraryEdited.tags"
                                    @click-tag="removeTag" />
                                <b-autocomplete
                                    v-model="tagText"
                                    :data="filteredExistingTags"
                                    expanded
                                    placeholder="select existing or add new library group"
                                    :open-on-focus="true"
                                    @select="addTag">
                                    <template #header>
                                        <a @click="addNewTag">
                                            <span> Add new library group </span>
                                        </a>
                                    </template>
                                    <template #empty>
                                        No existing library groups found
                                    </template>
                                </b-autocomplete>
                            </div>

                            <div class="column">
                                <p class="title is-5">
                                    Access Groups
                                </p>
                                <tag-list
                                    v-require-is-administrator
                                    :icon-class="'fa fa-trash'"
                                    type="is-danger"
                                    :tags="libraryEdited.accessGroups"
                                    @click-tag="removeAccessGroup" />
                                <b-field grouped>
                                    <b-autocomplete
                                        v-model="agText"
                                        :data="filteredAccessGroups"
                                        expanded
                                        placeholder="select existing access group"
                                        :open-on-focus="true"
                                        @select="addAccessGroup">
                                        <template #empty>
                                            No existing access group found
                                        </template>
                                    </b-autocomplete>
                                </b-field>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div
                v-if="!libraryEdited.isForeignObject"
                class="column is-10 is-offset-1 has-margin-top2 box">
                <attribute-def-edit
                    ref="attributeEdit"
                    :attributedefs="libraryEdited.attributeDefinitions"
                    :library="libraryEdited" />
            </div>
        </div>
    </spinner>
</template>

<script>
    import _ from 'lodash';
    import AttributeDefEdit from './AttributeDefEdit.vue';
    import Spinner from '@/shared/components/Spinner.vue';
    import TagList from '@/shared/components/TagList.vue';
    import { requireIsAdministrator } from '@/shared/directives/requirePermission';
    import { showMixin } from '@/shared/mixins/showMixin';
    import {
        untagLibrary,
        getAllTags,
        tagLibrary,
        postAccessGroupLibrary,
        deleteAccessGroupLibrary,
        patchLibrary,
        getAccessGroups,
        getAttributeDefinitions,
        getScopeTypes,
        getLibrary,
        getSpecializedUIs
    } from '@/shared/helpers/api';

    export default {
        components: {
            AttributeDefEdit,
            Spinner,
            TagList
        },
        directives: {
            'require-is-administrator': requireIsAdministrator
        },
        mixins: [
            showMixin
        ],
        props: {
            libraryName: {
                type: String,
                required: true
            }
        },
        emits: [
            'done'
        ],
        data() {
            return {
                saveLoading: false,
                loading: true,
                library: null,
                libraryEdited: null,
                accessGroups: [],
                libraryErrors: [],
                libraryTypeOptions: [
                    { name: 'Normal', value: false },
                    { name: 'Foreign object', value: true }
                ],
                existingTags: null,
                tagText: '',
                agText: '',
                scopeTypes: [],
                specializedUIs: []
            };
        },
        computed: {
            filteredExistingTags() {
                if (this.existingTags) {
                    const tagText = this.tagText.toLowerCase();
                    return this.existingTags
                        .filter(t => !this.libraryEdited.tags.includes(t))
                        .filter(t => tagText === '' ? true : t.toLowerCase().includes(tagText))
                        .sort((a, b) => a > b ? 1 : -1);
                } else {
                    return [];
                }
            },
            filteredAccessGroups() {
                if (this.accessGroups) {
                    const agText = this.agText.toLowerCase();
                    return this.accessGroups
                        .map(g => g.name)
                        .filter(g => !this.libraryEdited.accessGroups.includes(g))
                        .filter(g => g.toLowerCase().includes(agText))
                        .sort((a, b) => a > b ? 1 : -1);
                } else {
                    return [];
                }
            },
            filteredScopeTypes() {
                return this.scopeTypes.filter(x => x !== this.library.name);
            }
        },
        async mounted() {
            const [
                library,
                attributeDefinitions,
                existingTags,
                accessGroups,
                scopeTypes,
                specializedUIs
            ] = await Promise.all([
                getLibrary(this, this.libraryName),
                getAttributeDefinitions(this, this.libraryName),
                getAllTags(this),
                getAccessGroups(this),
                getScopeTypes(this),
                getSpecializedUIs(this)
            ]);

            this.library = library;
            this.libraryEdited = JSON.parse(JSON.stringify(this.library));
            this.libraryEdited.attributeDefinitions = attributeDefinitions;
            this.existingTags = existingTags;
            this.accessGroups = accessGroups;
            this.scopeTypes = scopeTypes.sort();
            this.specializedUIs = specializedUIs;
            this.loading = false;
        },
        methods: {
            addNewTag() {
                this.$buefy.dialog.prompt({
                    message: 'New Tag Name:',
                    inputAttrs: {
                        minlength: 1,
                        maxlength: 64,
                        required: true
                    },
                    onConfirm: this.addTag
                });
            },
            addTag(tag) {
                if (!this.libraryEdited.tags.includes(tag) && tag !== null) {
                    this.libraryEdited.tags.push(tag);
                    setTimeout(() => this.tagText = '', 100);
                }
            },
            removeTag(tag) {
                this.libraryEdited.tags.splice(this.libraryEdited.tags.indexOf(tag), 1);
            },
            removeAccessGroup(group) {
                this.libraryEdited.accessGroups.splice(this.libraryEdited.accessGroups.indexOf(group), 1);
            },
            addAccessGroup(group) {
                if (!group)
                    return;
                this.libraryEdited.accessGroups.push(group);
                setTimeout(() => this.agText = '', 100);
            },
            async initSave() {
                this.saveLoading = true;
                const diff = (a1, a2) => ({ new: a1.filter(x => !a2.includes(x)), del: a2.filter(x => !a1.includes(x)) });
                const tagDiff = diff(this.libraryEdited.tags, this.library.tags);
                const agDiff = diff(this.libraryEdited.accessGroups, this.library.accessGroups);
                try {
                    if (!this.libraryEdited.isForeignObject)
                        await this.$refs.attributeEdit.updateAttributes();
                    const updatedLib = await this.updateLibrary();
                    const updatedTag = await this.updateTags(tagDiff);
                    const updatedAg = await this.updateAg(agDiff);
                    if (updatedLib && updatedTag && updatedAg) {
                        this.$emit('done');
                    }
                    this.saveLoading = false;
                } catch (err) {
                    this.showError(err);
                    this.saveLoading = false;
                }
            },
            async updateTags(diff) {
                try {
                    await Promise.all(diff.new.map(tag => tagLibrary(this, tag, this.library.name)));
                    await Promise.all(diff.del.map(tag => untagLibrary(this, tag, this.library.name)));
                    return true;
                } catch {
                    this.showError('Error in updating library tags');
                    return false;
                }
            },
            async updateAg(diff) {
                try {
                    await Promise.all(diff.new.map(ag => postAccessGroupLibrary(this, ag, this.library.name)));
                    await Promise.all(diff.del.map(ag => deleteAccessGroupLibrary(this, ag, this.library.name)));
                    return true;
                } catch {
                    this.showError('Error in updating access groups');
                    return false;
                }
            },
            async updateLibrary() {
                const {
                    name,
                    description,
                    definition,
                    codeNameRegex,
                    descriptionRegex,
                    alias,
                    nameAlias,
                    specializedUI,
                    isValid,
                    isCaseSensitive,
                    isReadOnly,
                    areNamesUpperCase,
                    nameInIdentity,
                    nameMayChange,
                    isForeignObject,
                    foreignAppName,
                    isGlobal,
                    isScopeSpecific,
                    scopeType,
                    descriptionIsRequired
                } = this.libraryEdited;

                const command = {
                    name,
                    description,
                    definition,
                    codeNameRegex,
                    descriptionRegex,
                    alias,
                    nameAlias,
                    specializedUI,
                    isValid,
                    isCaseSensitive,
                    isReadOnly,
                    areNamesUpperCase,
                    nameInIdentity,
                    nameMayChange,
                    isForeignObject,
                    foreignAppName,
                    isGlobal,
                    isScopeSpecific,
                    scopeType,
                    descriptionIsRequired
                };

                try {
                    await patchLibrary(this, this.library.name, command);
                    return true;
                } catch (err) {
                    this.libraryErrors = err.response.data.errors;
                    this.showError(_.get(err, 'response.data.Message', 'unable to update library'));

                    // Sleep 4 sec to delay page reload's so that the user can read error message
                    await new Promise(r => setTimeout(r, 4000));
                    return false;
                }
            },
            newAD() {
                this.$refs.attributeEdit.newAD();
            },
            cancel() {
                this.$emit('done');
            }
        }

    };
</script>
