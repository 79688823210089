/**
 * @typedef {import('vue').Directive} Directive
 */

/**
 * Sets or unsets an attribute on an element
 * @param {HTMLElement} el
 * @param {string} attributeName
 * @param {boolean} value
 */
function toggleAttribute(el, attributeName, value) {
    if (value) {
        el.setAttribute(attributeName, '');
    } else {
        el.removeAttribute(attributeName);
    }
}

/**
 * A tracked subtree of the DOM. Tracks and enforces the disabled and hidden state of elements within the subtree based
 * on access.
 */
class Tree {
    /**
     * All tracked subtrees on the page.
     * @type {Set<Tree>}
     */
    static all = new Set();

    static isRefreshQueued = false;

    /**
     * The root element of the tracked subtree
     * @type {HTMLElement}
     */
    root;

    /**
     * @type {boolean | null}
     */
    hasAccess;

    /**
     * @type {boolean}
     */
    shouldHide;

    isDirty = false;

    /**
     * Holds the original `disabled` value of each element tracked in this DOM tree.
     * @type {WeakMap<HTMLElement, boolean>}
     */
    originalDisabledStates = new WeakMap();

    /**
     * Used to keep track of any mutations to `disabled` attributes that are not performed by this class.
     * @type {MutationObserver}
     */
    observer;

    /**
     * @param {HTMLElement} root
     * @param {boolean | null} hasAccess
     * @param {boolean} shouldHide
     */
    constructor(root, hasAccess, shouldHide) {
        Tree.all.add(this);

        this.root = root;
        this.hasAccess = hasAccess;
        this.shouldHide = shouldHide;
        this.isDirty = true;
        this.observer = new MutationObserver((mutations) => {
            this.processMutations(mutations);
            Tree.refresh();
        });
        this.observer.observe(root, {
            subtree: true,
            childList: true,
            attributes: true,
            attributeFilter: ['disabled'],
            attributeOldValue: false
        });

        Tree.refresh();
    }

    dispose() {
        this.observer.disconnect();
        Tree.all.delete(this);
    }

    /**
     * @param {MutationRecord[]} mutations
     */
    processMutations(mutations) {
        for (const mutation of mutations) {
            const target = mutation.target;

            if (mutation.attributeName === 'disabled') {
                this.originalDisabledStates.set(
                    target,
                    target.hasAttribute('disabled')
                );
            }

            this.isDirty = true;
        }
    }

    /**
     * @param {boolean | null} hasAccess
     * @param {boolean} shouldHide
     */
    update(hasAccess, shouldHide) {
        this.hasAccess = hasAccess;
        this.shouldHide = shouldHide;
        this.isDirty = true;
        Tree.refresh();
    }

    static count = 0;

    // The reason for refreshing every tree at once is because one tree can contain another. If we only refresh and
    // discard pending mutations for the child, the parent's mutation observer will be triggered at some point,
    // which will again trigger the child's observer, creating an infinite loop of refreshes...
    static refresh() {
        if (Tree.isRefreshQueued) {
            return;
        }
        Tree.isRefreshQueued = true;

        requestAnimationFrame(() => {
            // Process pending mutations that need to be tracked
            Tree.processPendingMutations();
            try {
                for (const tree of Tree.all) {
                    tree.reapplyDisabled();
                }
            } finally {
                // Discard pending mutations that should not be tracked
                Tree.discardPendingMutations();
                Tree.isRefreshQueued = false;
            }
        });
    }

    /**
     * @param {HTMLElement} el
     */
    setDisabledAttribute(el) {
        if (!this.originalDisabledStates.has(el)) {
            this.originalDisabledStates.set(el, el.hasAttribute('disabled'));
        }

        const isOriginallyDisabled = this.originalDisabledStates.get(el);

        toggleAttribute(el, 'disabled', !this.hasAccess || isOriginallyDisabled);
    }

    reapplyDisabled() {
        if (!this.isDirty) {
            return;
        }

        if (this.shouldHide) {
            this.root.hidden = !this.hasAccess;
        } else {
            const buttonElements = this.root.getElementsByTagName('button');
            if (buttonElements.length) {
                for (const button of buttonElements) {
                    this.setDisabledAttribute(button);
                    button.title = this.hasAccess !== false ? '' : 'You are not authorized for this action';
                }
            } else if (this.root instanceof HTMLButtonElement) {
                this.setDisabledAttribute(this.root);
                this.root.title = this.hasAccess !== false ? '' : 'You are not authorized for this action';
            } else if (this.root instanceof HTMLAnchorElement) {
                this.setDisabledAttribute(this.root);
                this.root.style.pointerEvents = this.hasAccess ? 'auto' : 'none';
                this.root.title = this.hasAccess !== false ? '' : 'You are not authorized for this action';
            } else if (this.root instanceof HTMLLabelElement) {
                this.setDisabledAttribute(this.root);
                this.root.style.cursor = this.hasAccess ? 'pointer' : 'no-drop';
                this.root.title = this.hasAccess !== false ? '' : 'You are not authorized for this action';
                if (this.root.childNodes[0]) {
                    this.setDisabledAttribute(this.root.childNodes[0]);
                }
            } else {
                this.root.style.display = this.hasAccess ? '' : 'none';
            }
        }

        this.isDirty = false;
    }

    static discardPendingMutations() {
        for (const tree of Tree.all) {
            tree.observer.takeRecords();
        }
    }

    static processPendingMutations() {
        for (const tree of Tree.all) {
            tree.processMutations(tree.observer.takeRecords());
        }
    }
}

/**
 * For each element bound to an access directive, its corresponding `Tree`.
 * @type {WeakMap<HTMLElement, Tree>}
 */
const trees = new WeakMap();

/**
 * @template V
 * @param {function(V): Promise<boolean | null> | boolean | null} checkAccess
 * @returns {Directive<HTMLElement, V>}
 */
export function createAccessDirective(checkAccess) {
    return {
        async mounted(el, binding) {
            const { arg: shouldHide, value: accessSpec } = binding;

            const tree = new Tree(el, false, shouldHide);
            trees.set(el, tree);
            const hasAccess = await checkAccess(accessSpec);
            tree.update(hasAccess, shouldHide);
        },
        async updated(el, binding) {
            const { arg: shouldHide, value: accessSpec } = binding;

            const tree = trees.get(el);
            try {
                const hasAccess = await checkAccess(accessSpec);
                tree.update(hasAccess, shouldHide);
            } catch (e) {
                tree.update(false, shouldHide);
                throw e;
            }
        },
        unmounted(el) {
            const tree = trees.get(el);
            trees.delete(el);

            tree.dispose();
        }
    };
}

/**
 * @typedef {string[] | {library?: string[], scope?: string[]}} RequiredAccessGroups
 */

/**
 * @typedef {Object} Policy
 * @property {string} sourceSystem
 * @property {string} facilityRole
 * @property {string} [scope]
 * @property {boolean} [commonLibrary]
 */

/**
 * @typedef {Object} PermissionsObject
 * @property {string[]} [permissions]
 * @property {string[]} [scopes]
 * @property {Policy[]} [policies]
 * @property {RequiredAccessGroups} [accessGroup]
 */

/**
 * @param {string} requiredPermission
 */
async function hasPermission(requiredPermission) {
    return await window.authService.hasPermission(requiredPermission);
}

/**
 * @param {string[]} requiredPermissions
 */
async function hasAnyPermission(requiredPermissions) {
    return await window.authService.hasAnyPermission(requiredPermissions);
}

// requireAnyPermission still used by TopMenu.vue
export const requireAnyPermission = createAccessDirective(hasAnyPermission);

/*
    NEW API
 */

import {
    validateAccessCanEditLibrary,
    validateAccessCanEditCode,
    validateAccessCanEditTagByName,
    validateAccessCanTransitionRelease
} from '@/shared/helpers/api.ts';
import { memoize } from '@/shared/helpers/timedMemoize.js';

export async function isAdministrator() {
    return await hasPermission('IsAdministrator');
}

async function canCreateCodeImport() {
    /* Used by ImportCodeSet ONLY, when the data being imported is unknown (Excel document) */
    return await hasPermission('CanEditRelease');
}

async function canCreateLibrary() {
    /* Used by NewLibraryButton ONLY */
    return await hasPermission('CanEditRelease');
}

/**
 * @param {string} libraryName
 */
async function canEditLibrary(libraryName) {
    /*
        Note on possible performance optimization:
        In this and the other methods that delegate to the backend for access validation we could
        short circuit for administrators. This would make it less likely that the developers (whom
        are all admins) would notice if something is wrong with the authorization code, so it should be avoided.

        if (await hasPermission('IsAdministrator'))
            return true;
     */

    return await validateAccessCanEditLibrary(window.authService, libraryName);
}

/**
 * @typedef {Object} CanEditCodeOptions
 * @property {string} [libraryName]
 * @property {string[]|undefined} [scopes]
 */

/**
 * @param {CanEditCodeOptions} options
 */
async function canEditCode(options) {
    return await validateAccessCanEditCode(window.authService, options.libraryName, options.scopes ?? []);
}

/**
 * @param {string} tag
 */
async function canEditTag(tag) {
    return await validateAccessCanEditTagByName(window.authService, tag);
}

/**
 * @param {number} releaseId
 */
async function canTransitionRelease(releaseId) {
    return await validateAccessCanTransitionRelease(window.authService, releaseId);
}

const memoizationOptions = {
    debug: false,
    timeout: 30 * 1000 // milliseconds
};

/**
 * @param {CanEditCodeOptions} options
 * @returns {string}
 */
function canEditCodeOptionsKey(options) {
    return `${options.libraryName}|${options.scopes ? options.scopes.join('-') : null}`;
}

export const requireIsAdministrator = createAccessDirective(memoize(isAdministrator, memoizationOptions));
export const requireCanCreateCodeImport = createAccessDirective(memoize(canCreateCodeImport, memoizationOptions));
export const requireCanCreateLibrary = createAccessDirective(memoize(canCreateLibrary, memoizationOptions));
export const requireCanEditLibrary = createAccessDirective(memoize(canEditLibrary, memoizationOptions));
export const requireCanEditTag = createAccessDirective(memoize(canEditTag, memoizationOptions));
export const requireCanTransitionRelease = createAccessDirective(memoize(canTransitionRelease, memoizationOptions));
export const requireCanEditCode = createAccessDirective(memoize(canEditCode, {
    ...memoizationOptions,
    cacheKeyFactory: canEditCodeOptionsKey
}));
