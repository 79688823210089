<template>
    <div>
        <nav class="is-flex is-justify-content-space-between mb-2">
            <div
                class="pt-2">
                <span
                    v-if="localSpec"
                    class="title is-5 is-oneline">
                    Specification
                </span>
            </div>
            <div
                class="is-flex is-gap-10 is-flex-direction-row-reverse is-flex-wrap-wrap is-justify-content-flex-start"
                style="row-gap: 0.5em">
                <button
                    v-if="editMode"
                    :disabled="!canFinishEditing"
                    class="button is-primary"
                    @click="editingDone">
                    <span class="icon is-small">
                        <i
                            class="fa fa-check-circle"
                            aria-hidden="true" />
                    </span>
                    <span>{{ isNewSpec ? 'Add new spec' : 'Done Editing' }}</span>
                </button>
                <p v-if="creatingMode">
                    <b-button
                        class="button is-secondary"
                        icon-left="close-circle"
                        @click="$emit('toggleCreateNew', false)">
                        <span>Cancel</span>
                    </b-button>
                </p>
                <p v-if="selectedSpec">
                    <toggle-button
                        v-require-can-edit-code="{ libraryName: 'CableSpec', scopes: [scope] }"
                        :disabled="wireColorEditMode"
                        :toggle="editMode && selectedSpec.id !== null"
                        :color="'is-info'"
                        :icon="'pencil'"
                        standard-text="Edit specification"
                        @toggle-button="$emit('toggleEditMode', true)"
                        @toggle-button-cancel="$emit('toggleEditMode', false)" />
                </p>
                <p v-if="editMode || hasChanges || isChanged">
                    <button
                        class="button is-secondary"
                        :disabled="!hasChanges && !isChanged"
                        @click="$emit('eraseChange')">
                        <span class="icon is-small">
                            <i class="fa fa-eraser" />
                        </span>
                        <span>Remove changes</span>
                    </button>
                </p>
            </div>
        </nav>

        <div
            v-if="isNewSpec && editMode && localspecExits"
            class="has-text-right">
            <span
                class="status-indicator is-danger">
                <b-icon
                    icon="alert-outline"
                    pack="mdi"
                    size="is-small" />
                Specification already exists
            </span>
        </div>

        <spinner
            :loading="loading"
            :fullscreen="false">
            <form
                v-if="localSpec && localSpec.values"
                :key="editMode"
                class="columns mt-1 is-multiline">
                <div class="outlined-box is-flex is-gap-10 mb-2 is-full-width">
                    <p
                        class="is-flex is-align-items-center is-flex-grow-1 mr-2">
                        <span class="mr-2 has-text-weight-bold">Name</span>
                        <b-input
                            class="is-flex-grow-1"
                            :disabled="!(editMode && isComposite)"
                            :placeholder="defaultShortName"
                            :model-value="localSpecShortName"
                            @update:model-value="customShortName = $event" />
                    </p>
                    <p
                        class="is-flex is-align-items-center ml-auto">
                        <span class="mr-2 has-text-weight-bold">Valid</span>
                        <bool-selector
                            :disabled="!editMode"
                            :value="localSpec.values.isValid ? 'True' : 'False'"
                            @bool-select="onToggleValidity($event)" />
                    </p>
                </div>
                <div class="outlined-box is-full-width">
                    <h2 class="title is-6">
                        Size
                    </h2>

                    <div class="flex-size">
                        <b-field
                            horizontal
                            class="size-field"
                            position="is-centered"
                            label="No. groups"
                            v-bind="fieldErrorProps.NumberOfGroups">
                            <flexible-number-input
                                v-model="localSpec.values.NumberOfGroups"
                                :disabled="!isNewSpec || !editMode || !selectedCode(libNames.cableCat).values.NumberOfGroupsFlag"
                                class="num-in-width" />
                        </b-field>

                        <b-field
                            horizontal
                            class="size-field"
                            label="No. conductor"
                            v-bind="fieldErrorProps.NumberOfWires">
                            <flexible-number-input
                                v-model="localSpec.values.NumberOfWires"
                                :disabled="!selectedCode(libNames.cableCat).values.NumberOfWiresFlag || !isNewSpec || !editMode"
                                class="num-in-width" />
                        </b-field>

                        <b-field
                            horizontal
                            class="cross-field"
                            :label="'Cross\u2011s' /* non-breaking hyphen*/"
                            v-bind="fieldErrorProps.CrossSectionSize">
                            <b-field>
                                <p class="control">
                                    <b-dropdown
                                        :triggers="['hover']"
                                        aria-role="list"
                                        :disabled="!isNewSpec || !editMode">
                                        <template #trigger>
                                            <b-button
                                                class="is-dropdown-trigger"
                                                :label="selectedUnit ? selectedUnit : selectedUnitLabel" />
                                        </template>
                                        <b-dropdown-item @click="selectUnit({ identity: selectedUnitLabel })">
                                            Clear Selected
                                        </b-dropdown-item>

                                        <b-dropdown-item
                                            v-for="option in originalCodes(libNames.cableCrossSectionUnit).map(c=> c.values)"
                                            :key="option.identity"
                                            aria-role="listitem"
                                            @click="selectUnit(option)">
                                            <b-tooltip
                                                :label="option.description"
                                                :delay="500"
                                                position="is-right">
                                                <span>{{ option.name }}</span>
                                            </b-tooltip>
                                        </b-dropdown-item>
                                    </b-dropdown>
                                </p>
                                <b-autocomplete
                                    v-model="crossSize"
                                    :disabled="(selectedUnit === selectedUnitLabel) || !isNewSpec || !editMode"
                                    :data="filteredSizeList"
                                    field="name"
                                    :open-on-focus="true">
                                    <template #default="props">
                                        <p> {{ props.option.name }} </p>
                                    </template>
                                </b-autocomplete>
                            </b-field>
                        </b-field>

                        <b-field
                            horizontal
                            class="size-field"
                            label="Grounding +E/G">
                            <b-select
                                v-model="localSpec.values.GroundFlagValue"
                                :disabled="!isNewSpec || !editMode || !selectedCode(libNames.cableCat).values.GroundFlag"
                                @update:model-value="groundingInput">
                                <option />
                                <option>E</option>
                                <option>G</option>
                            </b-select>
                        </b-field>
                        <b-field
                            horizontal
                            class="size-field"
                            :label="getLabelOfAttributeDef('BraidCrossSection')"
                            v-bind="fieldErrorProps.BraidCrossSection">
                            <flexible-number-input
                                v-model="localSpec.values.BraidCrossSection"
                                :disabled="!isNewSpec || !editMode || !selectedCode(libNames.cableCat).values.BraidCrossSectionFlag"
                                class="num-in-width" />
                        </b-field>

                        <b-field
                            horizontal
                            class="size-field"
                            :label="getLabelOfAttributeDef('Cladding')">
                            <flexible-number-input
                                v-model="localSpec.values.Cladding"
                                :disabled="!isNewSpec || !editMode || !selectedCode(libNames.cableCat).values.CladdingFlag"
                                class="num-in-width"
                                name="subject" />
                        </b-field>
                    </div>
                </div>

                <div class="split-view is-full">
                    <div class="column half-size p-0">
                        <div class="outlined-box">
                            <div class="flex-size-small">
                                <b-field
                                    horizontal
                                    class="size-field"
                                    label="Weight (kg/m)"
                                    v-bind="fieldErrorProps.WeightPerMeter">
                                    <flexible-number-input
                                        v-model="localSpec.values.WeightPerMeter"
                                        class="num-in-width-l"
                                        :disabled="!editMode" />
                                </b-field>
                                <b-field
                                    horizontal
                                    class="size-field"
                                    :label="getLabelOfAttributeDef('BendRadius')"
                                    v-bind="fieldErrorProps.BendRadius">
                                    <flexible-number-input
                                        v-model="localSpec.values.BendRadius"
                                        class="num-in-width-l"
                                        :disabled="!editMode" />
                                </b-field>
                                <b-field
                                    horizontal
                                    class="size-field"
                                    :label="getLabelOfAttributeDef('OuterDiameter')"
                                    v-bind="fieldErrorProps.OuterDiameter">
                                    <flexible-number-input
                                        v-model="localSpec.values.OuterDiameter"
                                        class="num-in-width-l"
                                        :disabled="!editMode" />
                                </b-field>
                                <b-field
                                    horizontal
                                    class="size-field"
                                    :label="getLabelOfAttributeDef('InnerDiameter')"
                                    v-bind="fieldErrorProps.InnerDiameter">
                                    <flexible-number-input
                                        v-model="localSpec.values.InnerDiameter"
                                        class="num-in-width-l"
                                        :disabled="!editMode" />
                                </b-field>
                            </div>
                        </div>
                        <div class="outlined-box">
                            <div class="flex-size-small">
                                <b-field
                                    horizontal
                                    class="size-field"
                                    label="Block depth (mm)"
                                    v-bind="fieldErrorProps.BlockDepth">
                                    <flexible-number-input
                                        v-model="localSpec.values.BlockDepth"
                                        class="num-in-width-l"
                                        :disabled="!editMode" />
                                </b-field>
                                <b-field
                                    horizontal
                                    class="size-field"
                                    label="Block width (mm)"
                                    v-bind="fieldErrorProps.BlockWidth">
                                    <flexible-number-input
                                        v-model="localSpec.values.BlockWidth"
                                        class="num-in-width-l"
                                        :disabled="!editMode" />
                                </b-field>
                            </div>
                        </div>
                        <div
                            v-if="composite && isNewSpec"
                            class="outlined-box is-full-width">
                            <b-field
                                class="is-is-composite-field is-oneline"
                                horizontal
                                label="Is composite">
                                <b-checkbox
                                    v-model="isComposite"
                                    :disabled="!editMode" />
                            </b-field>
                            <p>
                                Enables setting a custom name for the specification.
                            </p>
                        </div>
                    </div>

                    <div class="column half-size p-0">
                        <div class="outlined-box">
                            <h2 class="title is-6">
                                Coax
                            </h2>
                            <div class="">
                                <b-field
                                    horizontal
                                    class="size-field"
                                    label="Impedance 200 MHz (Ohm)"
                                    v-bind="fieldErrorProps.Impedance">
                                    <flexible-number-input
                                        v-model="localSpec.values.Impedance"
                                        class="num-in-width"
                                        :disabled="!editMode || !isCoax" />
                                </b-field>
                            </div>
                        </div>

                        <div class="outlined-box">
                            <b-field
                                horizontal
                                class="size-field cur-field"
                                label="Current (A)"
                                v-bind="fieldErrorProps.CurrentRating">
                                <flexible-number-input
                                    v-model="localSpec.values.CurrentRating"
                                    :disabled="!editMode"
                                    class="num-in-width-xl" />
                            </b-field>
                            <b-field
                                horizontal
                                class="size-field cur-field"
                                label="Short c. 1 sec (kA)"
                                v-bind="fieldErrorProps.ShortCirc1">
                                <flexible-number-input
                                    v-model="localSpec.values.ShortCirc1"
                                    :disabled="!editMode"
                                    class="num-in-width-xl" />
                            </b-field>
                            <b-field
                                horizontal
                                class="size-field cur-field"
                                label="Short c. 0,3 sec (kA)"
                                v-bind="fieldErrorProps.ShortCirc2">
                                <flexible-number-input
                                    v-model="localSpec.values.ShortCirc2"
                                    :disabled="!editMode"
                                    class="num-in-width-xl" />
                            </b-field>
                        </div>
                        <div class="outlined-box">
                            <p class="has-text-right mr-freq-header has-text-weight-bold">
                                Frequency (Hz)
                            </p>
                            <b-field
                                horizontal
                                class="size-field freq-field freq-field-top"
                                label="Inductance (mH/km)">
                                <b-field
                                    v-bind="fieldErrorProps.InductanceHz50">
                                    <div>
                                        <p class="has-text-centered has-text-weight-bold">
                                            50
                                        </p>
                                        <flexible-number-input
                                            v-model="localSpec.values.InductanceHz50"
                                            :disabled="!editMode"
                                            class="num-in-width" />
                                    </div>
                                </b-field>
                                <b-field
                                    v-bind="fieldErrorProps.InductanceHz60">
                                    <div>
                                        <p class="has-text-centered has-text-weight-bold">
                                            60
                                        </p>
                                        <flexible-number-input
                                            v-model="localSpec.values.InductanceHz60"
                                            :disabled="!editMode"
                                            class="num-in-width" />
                                    </div>
                                </b-field>
                            </b-field>
                            <b-field
                                horizontal
                                class="size-field freq-field"
                                label="Capacitance (nano F/km)">
                                <b-field
                                    v-bind="fieldErrorProps.CapasitanceHz50">
                                    <flexible-number-input
                                        v-model="localSpec.values.CapasitanceHz50"
                                        :disabled="!editMode"
                                        class="num-in-width" />
                                </b-field>
                                <b-field
                                    v-bind="fieldErrorProps.CapasitanceHz60">
                                    <flexible-number-input
                                        v-model="localSpec.values.CapasitanceHz60"
                                        :disabled="!editMode"
                                        class="num-in-width" />
                                </b-field>
                            </b-field>
                            <b-field
                                horizontal
                                class="size-field freq-field"
                                label="Resistance v/20 C (Ohm/km)">
                                <b-field
                                    v-bind="fieldErrorProps.Resistance20Hz50">
                                    <flexible-number-input
                                        v-model="localSpec.values.Resistance20Hz50"
                                        :disabled="!editMode"
                                        class="num-in-width" />
                                </b-field>
                                <b-field
                                    v-bind="fieldErrorProps.Resistance20Hz60">
                                    <flexible-number-input
                                        v-model="localSpec.values.Resistance20Hz60"
                                        :disabled="!editMode"
                                        class="num-in-width" />
                                </b-field>
                            </b-field>
                            <b-field
                                horizontal
                                class="size-field freq-field"
                                label="Resistance v/80 C (Ohm/km)">
                                <b-field
                                    v-bind="fieldErrorProps.Resistance80Hz50">
                                    <flexible-number-input
                                        v-model="localSpec.values.Resistance80Hz50"
                                        :disabled="!editMode"
                                        class="num-in-width" />
                                </b-field>
                                <b-field
                                    v-bind="fieldErrorProps.Resistance80Hz60">
                                    <flexible-number-input
                                        v-model="localSpec.values.Resistance80Hz60"
                                        :disabled="!editMode"
                                        class="num-in-width" />
                                </b-field>
                            </b-field>
                        </div>
                    </div>
                </div>
            </form>
        </spinner>
    </div>
</template>

<script>

    import _ from 'lodash';
    import {
        createSpecCheckName,
        createSpecShortName,
        createSpecTemplate,
        libNames
    } from '../../../shared/helpers/cableEditHelpers';
    import BoolSelector from '../../../shared/components/BoolSelector.vue';
    import CodeRefSelector from '../../../shared/components/CodeRefSelector.vue';
    import { showMixin } from '../../../shared/mixins/showMixin';
    import { mapActions, mapState, mapStores } from 'pinia';
    import Spinner from '../../../shared/components/Spinner.vue';
    import ToggleButton from '../../../shared/components/ToggleButton.vue';
    import { requireCanEditCode } from '@/shared/directives/requirePermission';
    import { useCodeEditStore } from '@/stores/codeEditStore.js';
    import FlexibleNumberInput from '@/apps/cableEdit/components/FlexibleNumberInput.vue';

    export default {
        components: {
            FlexibleNumberInput,
            BoolSelector,
            CodeRefSelector,
            ToggleButton,
            Spinner
        },
        directives: {
            requireCanEditCode
        },
        mixins: [
            showMixin
        ],
        props: {
            selectedSpec: {
                type: Object,
                required: false,
                default: null
            },
            editMode: {
                type: Boolean,
                required: true
            },
            creatingMode: {
                type: Boolean,
                required: true
            },
            wireColorEditMode: {
                type: Boolean,
                required: true
            },
            composite: {
                type: Boolean,
                required: false,
                default: false
            },
            specList: {
                type: Array,
                required: false,
                default: null
            }
        },
        emits: [
            'eraseChange',
            'toggleEditMode',
            'toggleCreateNew',
            'edited',
            'done'
        ],
        data() {
            return {
                libNames,
                isNewSpec: null,
                localSpec: null,
                selectedUnitLabel: 'Select a unit',
                selectedUnit: this.selectedUnitLabel,
                isComposite: false,
                customShortName: null,
                originalSpec: null,
                loading: false,
                crossSize: ''
            };
        },
        computed: {
            ...mapStores(useCodeEditStore),
            ...mapState(useCodeEditStore, ['scope']),
            canFinishEditing() {
                return this.specIsValid
                    && (this.hasChanges || this.isNewSpec)
                    && (!this.isNewSpec || !this.localspecExits);
            },
            specIsValid() {
                return _.isEmpty(this.validationErrors);
            },
            validationErrors() {
                const errors = {};

                if (!this.localSpec)
                    return errors;

                for (const fieldName of this.numberInputNames) {
                    const fieldValue = this.localSpec.values[fieldName];
                    if (fieldValue === '')
                        continue;

                    const inputType = this.attributeDefs.find(a => a.field === fieldName).type.toLowerCase();
                    if (inputType === 'float' && !isFloat(fieldValue)) {
                        errors[fieldName] ??= `Value must be a decimal number (e.g. ${3.14}).`;
                    } else if (inputType === 'int' && !Number.isInteger(fieldValue)) {
                        errors[fieldName] ??= 'Value must be an integer.';
                    }
                }

                for (const reqField of this.requiredFields) {
                    if (this.localSpec.values[reqField] === '') {
                        errors[reqField] ??= 'Value is required.';
                    }
                }

                if (this.isNewSpec && !this.isValidCrossSize) {
                    errors.CrossSectionSize ??= 'Not a valid cross section size.';
                }

                return errors;

                function isFloat(n) {
                    return typeof n === 'number' && !Number.isNaN(n);
                }
            },
            fieldErrorProps() {
                return _.mapValues(this.validationErrors, (error) => ({
                    message: error,
                    type: 'is-danger'
                }));
            },
            hasChanges() {
                if (!this.localSpec) return false;

                const isEdited = !_.isEqual(this.localSpec, this.originalSpec);
                this.$emit('edited', isEdited);
                return isEdited;
            },
            localspecExits() {
                return this.specList.some(
                    c => c.name === createSpecCheckName(
                        this.localSpec,
                        this.selectedCode(this.libNames.cableCat)
                    )
                );
            },
            localspecIsNew() {
                return this.localSpec?.meta?.isNew;
            },
            crossSizeList() {
                const codes = this.codeEditStore.originalCodes(this.libNames.cableSize);
                return codes
                    ? codes
                        .map(c => c.values)
                        .filter(c => c.Unit === this.selectedUnit)
                    : [];
            },
            filteredSizeList() {
                const search = this.crossSize.toLowerCase();

                return this.crossSizeList.filter(ref => ref.name.toLowerCase().includes(search))
                    .sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true }));
            },
            isCoax() {
                return this.selectedCode(libNames.cableCat).values.identity.toLowerCase() === 'c';
            },
            isValidCrossSize() {
                if (this.selectedUnit === this.selectedUnitLabel)
                    return true;

                return this.crossSizeList.map(x => x.name).includes(this.crossSize);
            },
            isChanged() {
                if (this.selectedSpec && this.selectedSpec.meta)
                    return this.selectedSpec.meta.isNew || this.selectedSpec.meta.edited;
                else
                    return false;
            },
            attributeDefs() {
                return this.codeEditStore.attributeDefinitions[libNames.cableSpec];
            },
            requiredFields() {
                return this.attributeDefs
                    .filter(a => a.required
                        && a.field !== 'identity'
                        && a.field !== 'name'
                        && a.field !== 'dateCreated'
                        && a.field !== 'dateUpdated')
                    .map(a => a.field);
            },
            numberInputNames() {
                return this.attributeDefs
                    .filter(attrDef => attrDef.type.toLowerCase() === 'int' || attrDef.type.toLowerCase() === 'float')
                    .map(numDefs => numDefs.field);
            },
            defaultShortName() {
                return createSpecShortName(this.localSpec, this.selectedCode(libNames.cableCat));
            },
            localSpecShortName() {
                if (this.isComposite && this.customShortName) {
                    return this.customShortName;
                } else if (this.localSpec.name) {
                    return this.localSpec.name;
                } else {
                    return null;
                }
            }
        },
        watch: {
            selectedSpec() {
                this.setLocalSpec();
            },
            editMode(mode) {
                if (!mode) {
                    this.setLocalSpec();
                }
            },
            crossSize(value) {
                const selectedCrossSize = this.crossSizeList.find(size => size.name === value);
                if (selectedCrossSize)
                    this.localSpec.values.CrossSectionSize = selectedCrossSize.identity;
            }
        },
        created() {
            this.setLocalSpec();
            this.originalSpec = _.cloneDeep(this.localSpec);
        },
        methods: {
            ...mapActions(useCodeEditStore, ['selectedCode', 'originalCodes', 'filteredCodes', 'attributes']),
            getLabelOfAttributeDef(attributeDefName) {
                return this.attributeDefs.find(attributeDef => attributeDef.field === attributeDefName).label;
            },
            setLocalSpec() {
                if (this.selectedSpec) {
                    this.localSpec = JSON.parse(JSON.stringify(this.filteredCodes(libNames.cableSpec)
                        .find(s => s.values.identity === this.selectedSpec.id)));
                    this.localSpec.name = this.selectedSpec.name;

                    this.selectedUnit = this.localSpec.values.CrossSectionSize.split('|')[1] ?? this.selectedUnitLabel;
                    this.isNewSpec = false;
                } else {
                    this.localSpec = createSpecTemplate(this.codeEditStore.templates[libNames.cableSpec],
                                                        this.selectedCode(libNames.cableCat),
                                                        this.codeEditStore.selectedCodes[libNames.cableCode]
                    );
                    this.selectedUnit = this.selectedUnitLabel;
                    this.isNewSpec = true;
                }
                this.originalSpec = _.cloneDeep(this.localSpec);
                this.crossSize = this.crossSizeList.find(size => size.identity === this.localSpec.values.CrossSectionSize)?.name ?? '';
                this.isComposite = false;
                this.customShortName = null;
            },
            async editingDone() {
                if (this.isNewSpec) {
                    const selectedCableCode = this.codeEditStore.selectedCodes[libNames.cableCode];
                    const shortName = this.localSpecShortName ?? this.defaultShortName;
                    this.localSpec.values.name = `${selectedCableCode} ${shortName}`;
                }

                const localChange = _.cloneDeep(this.localSpec);

                if (this.specIsValid) {
                    await this.addChange(localChange);
                }
                this.$emit('done', localChange);
                this.setLocalSpec();
            },
            async addChange(code) {
                try {
                    this.loading = true;
                    await this.codeEditStore.changeCode(libNames.cableSpec, code, this.isNewSpec ? 'create' : 'update');
                } catch (error) {
                    if (error) {
                        this.showAlert(this, _.get(error, 'title', 'Error'), _.get(error, 'message', 'Something went wrong'));
                    }
                } finally {
                    this.loading = false;
                }
            },
            groundingInput(input) {
                if (input === '') {
                    this.localSpec.values.GroundFlag = 'False';
                    this.localSpec.values.GroundFlagValue = '';
                } else {
                    this.localSpec.values.GroundFlag = 'True';
                    this.localSpec.values.GroundFlagValue = input;
                }
            },
            selectUnit(value) {
                this.localSpec.values.CrossSectionSize = '';
                this.crossSize = '';
                this.selectedUnit = value.identity;
            },
            onToggleValidity(value) {
                value = value.toString().toLowerCase();
                this.localSpec.values.isValid = value === 'true';
            }
        }
    };
</script>

<style scoped>
    .outlined-box {
        min-width: min-content;
    }
    .outlined-box :deep(.title) {
        margin-bottom: 1rem;
    }
    form {
        margin: 0;
    }
    form :deep(.field) {
        min-width: min-content;
    }
    form :deep(.size-field .field-label),
    form :deep(.cross-field .field-label),
    .valid-field :deep(.field-label)
    {
        margin-right: 0.75em;
        padding-top: 0;
        align-self: center;
    }
    form :deep(.field-body),
    .valid-field :deep(.field-body)
    {
        align-self: center;
    }
    .freq-field-top {
        align-items: center;
    }
    :deep(.freq-field-top.size-field .field-label) {
        padding-top: 1.25em;
    }
    .flex-size {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
        column-gap: 1.25em;
    }
    .flex-size-small {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
        column-gap: 0.75em;
    }
    .size-field :deep(.field-body) {
        flex-grow: 0;
    }
    .cross-field :deep(.field-body) {
        flex-grow: 0;
    }
    .cross-field :deep(.input) {
        min-width: 8ch;
    }
    .num-in-width {
        min-width: 80px;
    }
    .num-in-width-l {
        min-width: 80px;
    }
    .num-in-width-xl {
        min-width: 80px;
    }
    .split-view {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-start;
        align-content: flex-start;
        gap: 0.5em;
    }
    .split-view .column {
        display: flex;
        flex-direction: column;
        margin-top: 0.5em;
        gap: 0.5em;
    }
    .half-size {
        min-width: 48%;
    }
    .mr-freq-header {
        margin-right: 3rem !important;
    }
    .status-indicator.is-danger {
        color: #FF1234;
    }
</style>
